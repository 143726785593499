import React from 'react';

import "./styles.css"

export default function Inf() {
    return (<>
        <div className="informacao">
            <div className="styles-inf">

                <div className="inf-content">
                <h3>Importância do projeto.</h3>

                <span>
                Com base em pesquisas, aproximadamente 20% dos 
                pacientes em reabilitação faltavam a terapia, com 
                a pandemia esse valor se aproximou dos 100%;

                <p>
                No último estudo e publicado em outubro de 2020, 
                não existia uma plataforma que suprisse a necessidade
                no processo de reabilitação.
                </p>
                </span>
                </div>

            </div >
        </div >

    </>)
}