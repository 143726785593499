import React from 'react';

import Paixão from "../../components/img/Paixão.svg";
import Ação from "../../components/img/Ação.svg";


import { Card } from 'react-bootstrap';

import Proposito from '../../components/img/Proposito.svg';
import Missao from '../../components/img/Missao.svg';
import propositonosso from '../../components/img/propositonosso.svg';

import "./styles.css"

export default function Valores() {
    return (<>
        <div className="valores">
            <div className="styles-Valores">
                <div>
                            <div className="conteudo">
                                <div className="conteudo-interno">
                                <h1>Quem Somos</h1>
                                
                                <p>Somos uma startup que acredita no potencial das pessoas e
                                    tecnologias para transformar as jornadas dos atores envolvidos 
                                    no ecossistema da saúde.</p>
                                    </div>
                            </div>
                </div>
                        <div className="card-principal">
                            <div classeName="card">
                            <Card border="light" style={{ width: '18rem' }}>
                                <Card.Header><h5>Nosso Proposito</h5></Card.Header>
                                <Card.Body>
                                <div className="card-img">
                                <Card.Title><img src={Proposito} className="Proposito" alt=""/></Card.Title>
                                </div>
                                <Card.Text>
                                    <p>

                                        Sonhamos e trabalhamos por um 
                                        sistema de saúde mais eficiente e de 
                                        qualidade para todos.
                                    </p>
                                </Card.Text>
                                </Card.Body>
                            </Card>

                            
                            <br />
                            </div>

                            <div classeName="card">
                            <Card border="light" style={{ width: '18rem' }}>
                                <Card.Header><h5>Missão</h5></Card.Header>
                                <Card.Body>
                                <div className="card-img">
                                <Card.Title><img src={Missao} className="Proposito" alt=""/></Card.Title>
                                </div>
                                <Card.Text>
                                    <p>
                                Ajudar Hospitais, Empresas de Home Care e
                                Operadoras a aumentarem sua eficiência, 
                                rentabilidade, qualidade e segurança 
                                no atendimento domiciliar.
                                    </p>
                                </Card.Text>
                                </Card.Body>
                            </Card>
                            <br />
                            </div>

                            <div classeName="card">
                            <Card border="light" style={{ width: '18rem' }}>
                                <Card.Header><h5>Nosso Propósito</h5></Card.Header>
                                <Card.Body>
                                <div className="card-img">
                                <Card.Title><img src={propositonosso} className="Proposito" alt=""/></Card.Title>
                                </div>
                                <Card.Text>
                                    <p>
                                Sonhamos e trabalhamos por um 
                                sistema de saúde mais eficiente e de 
                                qualidade para todos.
                                    </p>
                                </Card.Text>
                                </Card.Body>
                            </Card>
                            <br />
                            </div>
                        </div>

                        
                </div>

        </div >

        {/*Camada que se refere a transparencia */}

      <div className="apoio">
        <div className="styles-apoio">
          <div className="title">
            <h1>Transparência</h1>
            <p>
              Promove a confiança, no qual é a base de um grande trabalho em
              equipe.
            </p>
          </div>

          <div className="icon-font">
            <div className="icon">
              <img src={Ação} className="Ação" alt="" />
              <p>
                Ação colaborativa que propiciar o potencial coletivo ao
                capacitar os indivíduos.
              </p>
            </div>

            <div className="icon">
              <img src={Paixão} className="Paixão" alt="" />
              <p>
                Gostamos do que fazemos e estamos continuamente Inovando e
                melhorando.
              </p>
            </div>
          </div>
        </div>
      </div>

    </>)
}