import React from "react";

import Alisson from "../../components/img/Alisson.png";
import Vinicius from "../../components/img/Vinicius.jpg";
import Douglas from "../../components/img/Douglas.png";
import Jakes from "../../components/img/Jakes.jpeg";
import Saggioro from "../../components/img/Saggioro.jpeg";

import "./styles.css"

export default function Time(){

return(<>
 <div id="Time" >
        
        <h1>Time</h1>

        <strong>
          Trabalhamos juntos, como um grande time! Na eclinic, valorizamos
          cada habilidade e sentimento dos colaboradores. Dessa forma, temos
          nos destacado com pesquisas e desenvolvimento de soluções que salvam
          vidas!
        </strong>
   

      <div className="Foto">
     
          <div className="name-foto">
            <img src={Jakes}  alt="CEO Jakes Dourado" />
            <div className="inf-desc">
              <p>Jakes Dourado</p>
              <span>CEO</span>
            </div>
          </div>
     

       
          <div className="name-foto">
            <img src={Saggioro}  alt="" />
            <div className="inf-desc">
              <p>Saggioro </p>
              <span>Back-End</span>
            </div>
          </div>
        

        <div>
          <div className="name-foto">
            <img src={Douglas}  alt="" />
            <div className="inf-desc">
              <p>Douglas </p>
              <span>Desenvolvedor Mobile</span>
            </div>
          </div>
        </div>

        <div>
          <div className="name-foto">
            <img src={Vinicius}  alt="" />
            <div className="inf-desc">
              <p>Vinicius </p>
              <span>Front-End</span>
            </div>
          </div>
        </div>

        <div>
          <div className="name-foto">
            <img src={Alisson}  alt="" />
            <div className="inf-desc">
              <p>Alisson</p>
              <span>Front-End</span>
            </div>
          </div>
        </div>
      </div>
    </div>

</>)


}
