import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from './pages/Main';

//import Main from './pages/Main';
//import QuemSomos from './pages/QuemSomos';
//import Informacao from './pages/Informacao';
//import Contato from './pages/Contato';


export default function Routes(){
    return( 
    <>
      <Switch>
          <Route path="/" exact component={Main}/>
      </Switch>
        </>
        );

    
    }