import React from "react";


import "./styles.css";

export default function Quemsomos() {
  return (
    <>
      <div className="quemSomos">
        <div className="ajuste-quemSomos">
          <div className="style-quemSomos">
            <strong>Mas o que é "Therapy cannot stopo"?</strong>
            <p>
              " Uma plataforma web que está sendo criada para auxiliar no
              tratamento da reabilitação de pacientes com paralisia cerebral com
              múltiplas deficiências "
              <br />
              <br />
              <label>Francisco Dourado</label>
            </p>
          </div>
        </div>
      </div>

    </>
  );
}
