import "./style.css";
import React from "react";

import { Navbar, Nav, Container } from "react-bootstrap";
import Logoeclinic from "./../img/logo-eclinic-branco.png";

export default function NavB() {
  return (
    <>
      <Navbar className="background-nav" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand link="#home">
            <img src={Logoeclinic} className="icon-nav" alt="logo eclinic" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="nav navbar-nav mx-auto me-auto">
              <Nav.Link href="#home">Quem Somos</Nav.Link>
              <Nav.Link href="#contato">Informações</Nav.Link>
              <Nav.Link href="#link">Contatos</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
