import React from "react";

import FAV from "../../components/img/FAV.png";
import CESAR from "../../components/img/CESAR.png";



import "./styles.css";

export default function Apoio() {
  return (
    <>
    <div id="apoio">
      <div className="ajuste-componente">
        <strong>Apaioadores</strong>
        </div>
        
        
        <div>

        <div className="logo-apoio">
          <img src={FAV} alt="" />
          <img src={CESAR}  alt="" />
         
        </div>
      </div>
      </div>
    </>
  );
}
