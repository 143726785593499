import './App.css';
import React from 'react';
import Routes from './routes';
import {BrowserRouter} from "react-router-dom";


function App (props) {
  return (
     <BrowserRouter>
     <Routes/>
    
      </BrowserRouter>
  );
};

export default App;
