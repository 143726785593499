import React from "react";

import logo from "../../components/img/Eclinic_2.png";

import "./styles.css";

export default function Banner() {
  return (
    <>
      <div className="banner">
        <div className="ajuste-banner">
            <span><img src={logo} alt="" /> </span>
          
        </div>
        {/** <p>
              Já imaginou fazer parte de um grupo de patrocinadores e apoiadores
              do projeto “ Therapy Cannot Stop”
            </p> */}
      </div>
    </>
  );
}
